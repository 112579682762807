import { useEffect } from 'react';
import { cookie } from '../services/cookie';
import { useLocation } from 'react-router-dom';
import { eventEmmiter } from '../helpers/eventEmmiter';
import { payloadCookieKey } from '../shared/constants';
import { isTokenExpired } from '../helpers/isTokenExpired';
import { useAuthenticationContext } from '../contexts/AuthenticationContext';

export const ValidateAuthentication = () => {
  const { pathname } = useLocation();
  const { logout, setLoggedIn } = useAuthenticationContext();
  useEffect(() => {
    const logoutEvent = () => logout();
    eventEmmiter.on('logout', logoutEvent);
    return () => {
      eventEmmiter.remove('logout');
    };
  }, [logout]);
  useEffect(() => {
    const payloadCookie = cookie.getCookie(payloadCookieKey);
    if (payloadCookie && !isTokenExpired(payloadCookie)) {
      setLoggedIn(true);
      return;
    }
    setLoggedIn(false);
  }, [pathname, setLoggedIn]);
};
