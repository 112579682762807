import { useState } from 'react';
import { Router } from './router';
import { cookie } from './services/cookie';
import { payloadCookieKey } from './shared/constants';
import { MantineProvider } from './providers/MantineProvider';
import { QueryClientProvider } from './providers/QueryClientProvider';
import { AuthenticationProvider } from './providers/AuthenticationProvider';

export const App = () => {
  const [loggedIn, setLoggedIn] = useState(
    Boolean(cookie.getCookie(payloadCookieKey)),
  );
  const logout = () => {
    cookie.removeCookie(payloadCookieKey);
    setLoggedIn(false);
  };
  // TODO: Dodati logiku za 409 ovde
  return (
    <AuthenticationProvider value={{ logout, loggedIn, setLoggedIn }}>
      <MantineProvider>
        <QueryClientProvider>
          <Router />
        </QueryClientProvider>
      </MantineProvider>
    </AuthenticationProvider>
  );
};
