import { isScheme } from '../helpers/isScheme';
import { useLocalStorage } from '@mantine/hooks';
import { ModalsProvider } from '@mantine/modals';
import { Notifications } from '@mantine/notifications';
import {
  ColorSchemeProvider,
  MantineProvider as Provider,
} from '@mantine/core';

const primaryColor = 'indigo';
const fontFamily = 'Montserrat, sans-serif';

export const MantineProvider = ({ children }) => {
  const [colorScheme, setColorScheme] = useLocalStorage({
    defaultValue: 'light',
    key: 'mantine-color-scheme',
    getInitialValueInEffect: true,
  });
  const toggleColorScheme = () =>
    setColorScheme(
      isScheme({ mode: 'dark', scheme: colorScheme }) ? 'light' : 'dark',
    );
  const theme = {
    fontFamily,
    primaryColor,
    headings: { fontFamily },
    fontFamilyMonospace: fontFamily,
    components: {
      Switch: {
        defaultProps: {
          size: 'md',
          onLabel: 'ON',
          offLabel: 'OFF',
        },
      },
      Badge: {
        defaultProps: {
          variant: colorScheme === 'dark' ? 'light' : 'outline',
        },
      },
    },
  };
  return (
    <ColorSchemeProvider
      colorScheme={colorScheme}
      toggleColorScheme={toggleColorScheme}
    >
      <Provider
        theme={{
          ...theme,
          colorScheme,
        }}
        withGlobalStyles
        withNormalizeCSS
      >
        <ModalsProvider>
          <Notifications limit={3} position="top-right" />
          {children}
        </ModalsProvider>
      </Provider>
    </ColorSchemeProvider>
  );
};
